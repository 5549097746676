import { useNavigate } from 'react-router-dom'
import { Button, Logo } from '../Atoms'

const Policies = () => {
    const navigate = useNavigate()
    return (
        <div className="w-full h-full overflow-auto">
            <div className="py-2">
                <Logo position="end" Shadow={false} />
            </div>
            <div className='py-8 px-8 md:px-20'>
                <div className='font-bold text-lg'>The M.O. Disclaimer</div>
                <br />
                <div>Legal Disclaimer, Terms of Service, and User Agreement for The M.O.
                    (Modus Operandi Holdings Ltd.)</div>
                <br />
                Address: House of Francis, Room 303, Ile du Port, Mahe, Seychelles
                <br />
                <br />
                <div>PLEASE READ THIS DOCUMENT CAREFULLY. IT CONTAINS VERY IMPORTANT
                    INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND
                    OBLIGATIONS. BY USING THE PLATFORM, YOU AGREE TO BE BOUND BY ALL
                    TERMS AND CONDITIONS BELOW.
                </div>
                <br />
                <ol className='list-decimal' type='1'>
                    <li>Definitions</li>
                    "Platform" refers to the cryptocurrency-based service operated by Modus
                    Operandi Holdings Ltd. ("The M.O."), facilitating transactions and project
                    management between buyers and sellers.
                    "User" refers to any individual or entity accessing or using the Platform,
                    including buyers and sellers.
                    "Transaction" refers to any exchange, transfer, or agreement facilitated
                    through the Platform.
                    "Commission" refers to the fee charged by The M.O. for facilitating
                    transactions on the Platform.
                    <li> Acknowledgment and Acceptance of Terms</li>
                    By accessing or using the Platform, you acknowledge that you have read,
                    understood, and agree to be bound by all terms and conditions set forth in
                    this document, as well as any additional terms, policies, or guidelines
                    referenced herein or provided by The M.O. These terms constitute a
                    legally binding agreement between you and The M.O.
                    <li>Platform Administration and Technology </li>
                    The Platform is administered by a smart contract on the Hedera
                    Hashgraph network. You acknowledge and accept that:<br />
                    a) The Platform's functionality is dependent on blockchain technology and
                    smart contracts.<br />
                    b) The M.O. is not responsible for any issues, failures, or losses resulting
                    from the use of this technology, including but not limited to network
                    congestion, network failures, smart contract bugs, or blockchain forks.<br />
                    c) You assume all risks associated with the use of blockchain technology
                    and smart contracts.<br />
                    d) The M.O. may upgrade or modify the underlying technology or smart
                    contracts at any time, which may affect Platform functionality or user
                    experience.<br />
                    <li>Comprehensive Limitation of Liability</li>
                    To the fullest extent permitted by applicable law, The M.O., its
                    subsidiaries, affiliates, licensors, service providers, content providers,
                    employees, agents, officers, and directors shall not be liable for any direct,
                    indirect, incidental, special, consequential, punitive, or exemplary
                    damages, including but not limited to:<br />
                    a) Loss of profits, revenue, data, use, goodwill, or other intangible losses <br />
                    b) Personal injury, property damage, or emotional distress<br />
                    c) Damages resulting from hacking, tampering, or unauthorized access to
                    user accounts or data<br />
                    d) Losses due to market volatility, cryptocurrency price fluctuations, or
                    economic conditions<br />
                    e) Damages resulting from errors, omissions, interruptions, deletion of
                    files, defects, viruses, delays in operation or transmission <br />
                    f) Damages resulting from the use or inability to use the Platform <br />
                    g) Damages resulting from unauthorized access to or alteration of
                    transmissions or data <br />
                    h) Damages resulting from statements or conduct of any third party on
                    the Platform<br />
                    i) Damages resulting from failed transactions, delayed transactions, or
                    transactions sent to incorrect addresses<br />
                    j) Any other matter relating to the Platform or its services<br />
                    k) Losses due to regulatory actions or changes in law affecting
                    cryptocurrencies or the Platform<br />
                    l) Damages resulting from the use of third-party wallets or storage
                    solutions<br />
                    m) Losses due to incorrect information provided by users
                    This limitation applies regardless of the basis or theory of liability,
                    including negligence, breach of contract, breach of warranty, or any other
                    legal theory, even if The M.O. has been advised of the possibility of such
                    damages.<br />
                    <li>Platform Usage and Legal Compliance</li>
                    You understand and agree that:<br />
                    a) The Platform is a tool to facilitate and administer contractual
                    agreements and payments between buyers and sellers, and does not
                    constitute an investment, security, or financial instrument.<br />
                    b) You are solely responsible for ensuring that your use of the Platform
                    complies with all applicable laws, regulations, and legal requirements in
                    your jurisdiction, including but not limited to securities laws, tax laws,
                    consumer protection laws, and data privacy regulations.<br />
                    c) The M.O. is not responsible for ensuring the legality of your use of the
                    Platform in your jurisdiction.<br />
                    d) You will not use the Platform for any illegal, fraudulent, or unauthorized
                    purpose, including but not limited to money laundering, terrorist financing,
                    or the evasion of sanctions.<br />
                    e) You will comply with all applicable laws, including but not limited to
                    securities laws, tax laws, and anti-money laundering regulations.<br />
                    f) The M.O. is not responsible for any tax liabilities or reporting obligations
                    that may arise from your use of the Platform.<br />
                    g) You will not engage in any activities that could damage, disable,
                    overburden, or impair the Platform's servers or networks.<br />
                    h) You will not attempt to gain unauthorized access to any part of the
                    Platform or any systems or networks connected to the Platform.<br />
                    <li>Indemnification</li>
                    You agree to indemnify, defend, and hold harmless The M.O. from and
                    against any and all claims, liabilities, damages, losses, costs, expenses,
                    fees (including reasonable attorneys' fees and legal costs), judgments,
                    and settlements of every kind arising from or relating to: <br />
                    a) Your use or misuse of the Platform<br />
                    b) Your violation of these Terms or any applicable laws or regulations<br />
                    c) Your violation of any rights of another person or entity<br />
                    d) Any content or information you submit, post, or transmit through the
                    Platform<br />
                    e) Any disputes or issues between yourself and any third party in relation<br />
                    to the Platform<br />
                    f) Any transactions or agreements entered into through the Platform<br />
                    g) Any allegations of fraud, misrepresentation, or deceptive practices
                    related to your use of the Platform<br />
                    h) Any breach of your representations or warranties made under these
                    Terms<br />
                    i) Any action taken by The M.O. as part of its investigation of a suspected
                    violation of these Terms or as a result of its finding or decision that a
                    violation of these Terms has occurred
                    This indemnification obligation shall survive the termination of your use of
                    the Platform and these Terms.<br />
                    <li> Anti-Money Laundering (AML) and Know Your Customer (KYC)
                        Compliance</li>
                    You agree and understand that: <br />
                    a) You will not use the Platform for any illegal activities, including but not
                    limited to money laundering, terrorist financing, or fraudulent activities. <br />
                    b) The M.O. reserves the right to implement and modify AML and KYC
                    procedures at any time to prevent illegal activities and comply with
                    regulatory requirements. <br />
                    c) Your use of the Platform requires you to provide additional information
                    for verification purposes, including but not limited to government-issued
                    identification, proof of address, source of funds, and any other information
                    deemed necessary by The M.O. or required by applicable laws and
                    regulations. <br />
                    d) You will promptly comply with any requests for information as part of
                    the KYC process and agree to periodic re-verification of your information. <br />
                    e) Failure to comply with KYC procedures or any suspicion of illegal activity
                    may result in the immediate termination of your access to the Platform,
                    the potential forfeiture of your funds, and reporting to relevant authorities. <br />
                    f) The M.O. has the right to freeze, seize, or close your account if it
                    suspects any illegal activity, non-compliance with these terms, or as
                    required by law enforcement or regulatory authorities. <br />
                    g) The M.O. may share your KYC information with other financial
                    institutions, regulatory bodies, or law enforcement agencies as required
                    by law or to prevent financial crimes. <br />
                    h) You consent to The M.O. conducting background checks, credit checks,
                    and other financial and identity verification processes as deemed
                    necessary. <br />
                    <li>Digital Currency Usage and Risks</li>
                    You acknowledge and agree that: <br />
                    a) The Platform exclusively supports transactions using USDC hts and
                    HBAR. <br />
                    b) You are solely responsible for the secure storage of your private keys
                    and for ensuring the transfer of these digital currencies into and out of the
                    Platform. <br />
                    c) The M.O. is not liable for any losses resulting from the use of other
                    digital currencies, fluctuations in digital currency values, or any issues
                    related to the underlying blockchain technology. <br />
                    d) You understand the risks associated with digital currencies, including
                    their volatile nature, the potential for total loss of value, regulatory
                    uncertainty, and technological vulnerabilities. <br />
                    e) The M.O. does not provide any warranty as to the future value, stability,
                    or liquidity of the supported digital currencies. <br />
                    f) You are responsible for reporting and paying all applicable taxes on your
                    cryptocurrency transactions and holdings. <br />
                    g) The M.O. is not responsible for any loss of funds due to user error,
                    including but not limited to sending funds to incorrect addresses, losing
                    private keys, or falling victim to phishing attacks or social engineering
                    schemes. <br />
                    h) You acknowledge that transactions on the blockchain are irreversible
                    and The M.O. cannot assist in recovering lost or misdirected funds. <br />
                    i) You understand that the regulatory status of cryptocurrencies is
                    uncertain and may change in the future, potentially impacting the legality
                    or functionality of the Platform. <br />
                    <li>Data Collection, Privacy, and Security</li>
                    You agree and consent to:  <br />
                    a) The collection, use, storage, and processing of your personal
                    information, including but not limited to email, name, address, financial
                    information, and transaction history, for KYC purposes, Platform
                    functionality, and as required by applicable laws and regulations. <br />
                    b) The storage and processing of your personal data in accordance with
                    The M.O.'s Privacy Policy, which is incorporated by reference into these
                    Terms. <br />
                    c) The potential sharing of your information with regulators, law
                    enforcement, or other third parties if required by law, court order, or to
                    protect The M.O.'s legal rights. <br />
                    d) The use of cookies, web beacons, and similar tracking technologies on
                    the Platform. <br />
                    e) Receiving communications from The M.O. related to your account,
                    Platform updates, and legal or regulatory notices. <br />
                    f) The implementation of reasonable security measures by The M.O. to
                    protect your data, while acknowledging that no system is completely
                    secure and that The M.O. cannot guarantee the absolute security of your
                    information. <br />
                    g) The potential transfer of your personal data to countries outside your
                    own, which may have different data protection standards. <br />
                    h) The retention of your data for as long as necessary to comply with legal
                    obligations, resolve disputes, and enforce agreements. <br />
                    <li>Intellectual Property Rights </li>
                    You acknowledge and agree that:  <br />
                    a) All content, features, and functionality of the Platform, including but not
                    limited to text, graphics, logos, icons, images, audio clips, digital
                    downloads, data compilations, and software, are the exclusive property of
                    The M.O. or its licensors and are protected by international copyright,
                    trademark, patent, trade secret, and other intellectual property laws. <br />
                    b) You will not copy, modify, create derivative works of, publicly display,
                    publicly perform, republish, download, store, transmit, or exploit any of
                    the material on the Platform without the prior written consent of The M.O. <br />
                    c) You grant The M.O. a worldwide, non-exclusive, royalty-free, perpetual,
                    irrevocable license to use, reproduce, modify, adapt, publish, translate,
                    create derivative works from, distribute, and display any content you
                    submit to the Platform, if that work has not been minted into an NFT and
                    transferred outside the platform. <br />
                    d) You grant The M.O. the right to apply creator’s royalties to an NFT as it
                    sees fit. <br />
                    e) You will not use any trademarks, service marks, or logos of The M.O.
                    without express written permission. <br />
                    f) You will not remove, alter, or obscure any copyright, trademark, or other
                    proprietary rights notices incorporated in or accompanying the Platform. <br />
                    <li>Dispute Resolution, Arbitration, and Governing Law </li>
                    You agree that: <br />
                    a) These Terms are governed by and construed in accordance with the
                    laws of Seychelles, without regard to its conflict of law provisions. <br />
                    b) Any disputes arising from these Terms or your use of the Platform will
                    be resolved exclusively through binding arbitration in Seychelles,
                    conducted in accordance with the rules of the Seychelles International
                    Arbitration Centre (SIAC). <br />
                    c) You waive any and all objections to the exercise of jurisdiction over you
                    by such arbitrators and to venue in Seychelles. <br />
                    d) Any cause of action or claim you may have arising out of or relating to
                    these Terms or the Platform must be commenced within one (1) year after
                    the cause of action accrues; otherwise, such cause of action or claim is
                    permanently barred. <br />
                    e) You waive your right to participate in class actions or class-wide
                    arbitration. <br />
                    f) Notwithstanding the foregoing, The M.O. reserves the right to seek
                    injunctive or other equitable relief in any court of competent jurisdiction. <br />
                    g) In the event that arbitration is not permitted by law, you agree to
                    submit to the exclusive jurisdiction of the courts of Seychelles. <br />
                    <li>Modifications to Terms and Platform</li>
                    You understand and agree that: <br />
                    a) The M.O. reserves the right to modify these Terms at any time without
                    prior notice. <br />
                    b) Any changes to the Terms will be effective immediately upon posting on
                    the Platform. <br />
                    c) Your continued use of the Platform after any such changes constitutes
                    your acceptance of the new Terms. <br />
                    d) The M.O. may modify, suspend, or discontinue any aspect of the
                    Platform at any time without notice or liability. <br />
                    e) The M.O. may implement new features, change transaction limits, or
                    alter the supported cryptocurrencies at its sole discretion. <br />
                    f) It is your responsibility to review these Terms periodically for any
                    changes. <br />
                    <li>Termination and Account Closure</li>
                    You acknowledge that: <br />
                    a) The M.O. may terminate or suspend your access to all or part of the
                    Platform, without notice, for any conduct that The M.O., in its sole
                    discretion, believes violates these Terms, is harmful to other users, The
                    M.O., or third parties, or for any other reason. <br />
                    b) Upon termination, all rights granted to you under these Terms will
                    immediately cease. <br />
                    c) Any fees paid hereunder are non-refundable. <br />
                    d) The M.O. may, at its discretion, provide you with a reasonable
                    timeframe to withdraw your funds upon account closure, subject to
                    completion of any pending investigations or legal proceedings. <br />
                    e) You may close your account at any time, subject to the completion of
                    any pending transactions and compliance with The M.O.'s account closure
                    procedures. <br />
                    f) The M.O. may retain certain information as required by law or for
                    legitimate business purposes after account closure. <br />
                    <li>Force Majeure</li>
                    The M.O. shall not be liable for any delay or failure to perform resulting
                    from causes outside its reasonable control, including but not limited to
                    acts of God, war, terrorism, riots, embargoes, acts of civil or military
                    authorities, fire, floods, accidents, network infrastructure failures, strikes,
                    or shortages of transportation facilities, fuel, energy, labor, or materials.
                    This clause also applies to any disruptions caused by internet failures,
                    cryptocurrency network issues, or regulatory actions affecting the
                    cryptocurrency market. <br />
                    <li>No Financial Advice or Guarantees</li>
                    You acknowledge and agree that: <br />
                    a) The M.O. does not provide any financial, investment, legal, or tax
                    advice through the Platform or any associated services. <br />
                    b) Any information provided on the Platform is for general informational
                    purposes only and should not be construed as professional advice. <br />
                    c) The M.O. makes no guarantees regarding the performance of any
                    cryptocurrencies, the success of any transactions, or the profitability of
                    using the Platform. <br />
                    d) You are solely responsible for evaluating the risks associated with any
                    transaction or use of the Platform. <br />
                    e) Past performance is not indicative of future results, and you should not
                    rely on historical data provided by the Platform to make investment
                    decisions. <br />
                    <li>Third-Party Services and Links</li>
                    You understand and agree that: <br />
                    a) The Platform may integrate with or provide links to third-party services,
                    websites, or resources. <br />
                    b) The M.O. is not responsible for the content, accuracy, or practices of
                    any third-party services. <br />
                    c) Your use of any third-party services is subject to their respective terms
                    of service and privacy policies. <br />
                    d) The M.O. does not endorse and is not responsible for any damage or
                    loss caused by your use of third-party services accessed through the
                    Platform. <br />
                    e) The inclusion of any third-party services does not imply a
                    recommendation or endorsement by The M.O. <br />
                    <li>Entire Agreement, Severability, and Survival</li>
                    You agree that: <br />
                    a) These Terms constitute the entire agreement between you and The
                    M.O. regarding the use of the Platform, superseding any prior agreements
                    or communications. <br />
                    b) If any provision of these Terms is found to be invalid or unenforceable,
                    that provision shall be limited or eliminated to the minimum extent
                    necessary, and the remaining provisions of these Terms will remain in full
                    force and effect. <br />
                    c) Sections relating to Limitation of Liability, Indemnification, Intellectual
                    Property Rights, and Dispute Resolution shall survive the termination of
                    these Terms. <br />
                    d) The failure of The M.O. to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. <br />
                    <li>Assignment</li>
                    The M.O. may assign or transfer these Terms, in whole or in part, without
                    restriction or notification to you. You may not assign or transfer any rights
                    or obligations under these Terms without the prior written consent of The
                    M.O. <br />
                    <li>Compliance with International Regulations</li>
                    You agree to comply with all applicable international regulations, including
                    but not limited to: <br />
                    a) United States export controls and economic sanctions laws <br />
                    b) European Union financial regulations <br />
                    c) United Nations Security Council resolutions <br />
                    d) Any other applicable international trade or financial regulations <br />
                    e) Local cryptocurrency and financial services regulations in your
                    jurisdiction <br />
                    <li>Commission and Fee Structure</li>
                    You acknowledge and agree that: <br />
                    a) The M.O. charges a commission on every transaction facilitated through
                    the Platform. <br />
                    b) The M.O. reserves the right to change the commission rate at any time
                    and at its sole discretion. <br />
                    c) Any changes to the commission rate will not affect contracts already
                    saved to the Hedera Hashgraph network, which will remain bound by the
                    commission rate agreed upon at the time of writing on-chain. <br />
                    d) It is your responsibility to review the current commission rate before
                    entering into any new transactions on the Platform. <br />
                    e) In addition to the commission, you may be subject to network fees, gas
                    fees, or other charges associated with blockchain transactions, which are
                    separate from and not controlled by The M.O. <br />
                    f) The M.O. may introduce additional fees or charges for specific services
                    or features on the Platform, and will provide notice of such changes. <br />
                    g) You are responsible for paying all fees and charges associated with your
                    use of the Platform, including any applicable taxes. <br />
                    h) Failure to pay required fees may result in the suspension or termination
                    of your access to the Platform. <br />
                    <li> User Representations and Warranties</li>
                    You represent and warrant that: <br />
                    a) You are of legal age to form a binding contract with The M.O. in your
                    jurisdiction. <br />
                    b) You have the full right, power, and authority to enter into and comply
                    with these Terms. <br />
                    c) Your use of the Platform does not violate any applicable laws,
                    regulations, or third-party rights. <br />
                    d) All information you provide to The M.O. is true, accurate, and complete,
                    and you will maintain the accuracy of such information. <br />
                    e) You are not subject to sanctions or otherwise designated on any list of
                    prohibited or restricted parties by any government authority. <br />
                    f) You are not using the Platform for any illegal purposes, including money
                    laundering, financing terrorism, or fraudulent activities. <br />
                    <li> Prohibited Activities</li>
                    You agree not to engage in any of the following prohibited activities: <br />
                    a) Violating any applicable laws, regulations, or these Terms. <br />
                    b) Impersonating any person or entity, or falsely stating or
                    misrepresenting your affiliation with a person or entity. <br />
                    c) Interfering with or disrupting the Platform or servers or networks
                    connected to the Platform. <br />
                    d) Attempting to gain unauthorized access to any portion of the Platform
                    or any other systems or networks connected to the Platform. <br />
                    e) Using the Platform to transmit any viruses, worms, defects, Trojan
                    horses, or other items of a destructive nature. <br />
                    f) Reverse engineering, decompiling, disassembling, or otherwise
                    attempting to derive the source code for any part of the Platform. <br />
                    g) Aggregating, copying, or duplicating any of the content or information
                    available from the Platform. <br />
                    h) Harassing, abusing, or harming another person through your use of the
                    Platform. <br />
                    i) Using the Platform in any manner that could interfere with, disrupt,
                    negatively affect, or inhibit other users from fully enjoying the Platform. <br />
                    <li> Notifications and Communications</li>
                    You agree that: <br />
                    a) The M.O. may provide you with notices, including those regarding
                    changes to these Terms, by email, regular mail, or postings on the
                    Platform. <br />
                    b) You consent to receive communications from The M.O. electronically,
                    and you agree that all agreements, notices, disclosures, and other
                    communications that The M.O. provides to you electronically satisfy any
                    legal requirement that such communications be in writing. <br />
                    c) You are responsible for maintaining the accuracy of your email address
                    and other contact information on file with The M.O. <br />
                    <li> Confidentiality </li>
                    You agree to maintain the confidentiality of any non-public information
                    received from The M.O. or other users through your use of the Platform.
                    This includes, but is not limited to, information about the Platform's
                    technology, business plans, and user data. <br />
                    <li> Language </li>
                    These Terms and all related documents are drafted in English. Any
                    translation into other languages is for your convenience only, and in the
                    event of any inconsistency, the English version shall prevail. <br />
                    <li>Waiver of Class Action Rights</li>
                    To the extent permitted by law, you waive any right to participate in a
                    class action lawsuit or class-wide arbitration against The M.O. or its
                    affiliates related to your use of the Platform. <br />
                    <li>Acknowledgment of Understanding and Agreement</li>
                    By using the Platform, you confirm that you have read, fully understand,
                    and agree to be bound by all of the terms and conditions set forth in this
                    document. You acknowledge that your use of the Platform constitutes your
                    acceptance of these Terms and that you have the legal capacity to enter
                    into this agreement. <br />  <br />
                    I have read, understood, and agree to all of the above Terms and
                    Conditions, including all subsections and clauses. I acknowledge that
                    checking this box constitutes a legal signature and that I am entering into
                    a binding agreement with The M.O. I understand that the commission
                    structure may be changed at any time for new contracts, and I accept all
                    risks associated with using this cryptocurrency-based platform. <br /><br />
                    I acknowledge that I have read, understood, and agree to be bound by all
                    of these Terms and Conditions. If you do not agree to all of these terms,
                    you must not use the Platform
                </ol>
                <br />
                <div className='max-w-xs mx-auto'>
                    <Button
                        onClick={() => navigate(-1)}
                        label="Back"
                        variant="Transparent"
                        color="secondary"
                        size="small"
                    />
                </div>
            </div>
        </div>
    )
}

export default Policies