// const CATEGORY_ARRAY = [
//     { "key": "DESIGN", "value": "Design" },
//     { "key": "RESEARCH", "value": "Research" },
//     { "key": "DEVELOPMENT", "value": "Development" },
//     { "key": "TESTING", "value": "Testing" },
//     { "key": "OTHER", "value": "Other" },
//     { "key": "ACCOUNT_MANAGEMENT", "value": "Account Management" },
//     { "key": "ACTOR_ACTRESS", "value": "Actor/Actress" },
//     { "key": "AD_OPERATIONS", "value": "Ad Operations" },
//     { "key": "ADVERTISING_COPYWRITING", "value": "Advertising Copywriting" },
//     { "key": "AI_ML_DEVELOPMENT", "value": "AI/ML Development" },
//     { "key": "APP_DEVELOPMENT", "value": "App Development" },
//     { "key": "AUDIO_ENGINEERING", "value": "Audio Engineering" },
//     { "key": "AUGMENTED_REALITY", "value": "Augmented Reality" },
//     { "key": "BACKEND_DEVELOPMENT", "value": "Backend Development" },
//     { "key": "BLOGGING", "value": "Blogging" },
//     { "key": "BRAND_CONSULTING", "value": "Brand Consulting" },
//     { "key": "BRAND_MANAGEMENT", "value": "Brand Management" },
//     { "key": "BRAND_REPRESENTATION", "value": "Brand Representation" },
//     { "key": "BRAND_STRATEGY", "value": "Brand Strategy" },
//     { "key": "CAMPAIGN_MANAGEMENT", "value": "Campaign Management" },
//     { "key": "CATERING", "value": "Catering" },
//     { "key": "CHEF", "value": "Chef" },
//     { "key": "CHIEF_BRAND_OFFICER", "value": "Chief Brand Officer" },
//     { "key": "CHIEF_MARKETING_OFFICER", "value": "Chief Marketing Officer" },
//     { "key": "CHOREOGRAPHY", "value": "Choreography" },
//     { "key": "CINEMATOGRAPHY", "value": "Cinematography" },
//     { "key": "CIRCUS_PERFORMANCE", "value": "Circus Performance" },
//     { "key": "COLOR_GRADING", "value": "Color Grading" },
//     { "key": "COMMUNICATIONS", "value": "Communications" },
//     { "key": "COMPOSITION", "value": "Composition" },
//     { "key": "CONCEPT_ART_DESIGN", "value": "Concept Art Design" },
//     { "key": "CONTENT_MARKETING", "value": "Content Marketing" },
//     { "key": "CONTENT_STRATEGY", "value": "Content Strategy" },
//     { "key": "CONTENT_WRITING", "value": "Content Writing" },
//     { "key": "COPYWRITING", "value": "Copywriting" },
//     { "key": "COSTUME_DESIGN", "value": "Costume Design" },
//     { "key": "CREATIVE_DIRECTION", "value": "Creative Direction" },
//     { "key": "CREATIVE_WRITING", "value": "Creative Writing" },
//     { "key": "CULINARY_BLOGGING", "value": "Culinary Blogging" },
//     { "key": "CULINARY_INSTRUCTION", "value": "Culinary Instruction" },
//     { "key": "DANCE", "value": "Dance" },
//     { "key": "DATABASE_ADMINISTRATION", "value": "Database Administration" },
//     { "key": "DEVOPS", "value": "DevOps" },
//     { "key": "DIRECTION", "value": "Direction" },
//     { "key": "DIGITAL_MARKETING", "value": "Digital Marketing" },
//     { "key": "DIGITAL_PAINTING", "value": "Digital Painting" },
//     { "key": "DIRECTOR_OF_PHOTOGRAPHY", "value": "Director of Photography" },
//     { "key": "DOCUMENTARY_FILMMAKING", "value": "Documentary Filmmaking" },
//     { "key": "DJING", "value": "DJing" },
//     { "key": "DRONE_PHOTOGRAPH_", "value": "Drone Photography" },
//     { "key": "EDITORIAL_WRITING", "value": "Editorial Writing" },
//     { "key": "EVENT_PLANNING", "value": "Event Planning" },
//     { "key": "EXHIBITION_DESIGN", "value": "Exhibition Design" },
//     { "key": "FASHION_DESIGN", "value": "Fashion Design" },
//     { "key": "FILM_EDITING", "value": "Film Editing" },
//     { "key": "FOLEY_ART", "value": "Foley Art" },
//     { "key": "FOOD_PHOTOGRAPHY", "value": "Food Photography" },
//     { "key": "FOOD_STYLING", "value": "Food Styling" },
//     { "key": "FRONTEND_DEVELOPMENT", "value": "Frontend Development" },
//     { "key": "FULL_STACK_DEVELOPMENT", "value": "Full Stack Development" },
//     { "key": "GAME_DESIGN", "value": "Game Design" },
//     { "key": "GAME_PROGRAMMING", "value": "Game Programming" },
//     { "key": "GHOSTWRITING", "value": "Ghostwriting" },
//     { "key": "GRAPHIC_DESIGN", "value": "Graphic Design" },
//     { "key": "ILLUSTRATION", "value": "Illustration" },
//     { "key": "IMPROVISATION", "value": "Improvisation" },
//     { "key": "INTERACTIVE_INSTALLATION", "value": "Interactive Installation" },
//     { "key": "INTERACTIVE_MEDIA", "value": "Interactive Media" },
//     { "key": "INTERACTIVE_STORYTELLING", "value": "Interactive Storytelling" },
//     { "key": "INTERIOR_DESIGN", "value": "Interior Design" },
//     { "key": "IT_CONSULTING", "value": "IT Consulting" },
//     { "key": "JINGLE_WRITING", "value": "Jingle Writing" },
//     { "key": "LANDSCAPE_DESIGN", "value": "Landscape Design" },
//     { "key": "LOGO_DESIGN", "value": "Logo Design" },
//     { "key": "MARKET_RESEARCH", "value": "Market Research" },
//     { "key": "MARKETING_ANALYSIS", "value": "Marketing Analysis" },
//     { "key": "MARKETING_COORDINATION", "value": "Marketing Coordination" },
//     { "key": "MARKETING_DATA_ANALYSIS", "value": "Marketing Data Analysis" },
//     { "key": "MARKETING_MANAGEMENT", "value": "Marketing Management" },
//     { "key": "MEDIA_PLANNING", "value": "Media Planning" },
//     { "key": "MENU_DESIGN", "value": "Menu Design" },
//     { "key": "MIXOLOGY", "value": "Mixology" },
//     { "key": "MOBILE_APP_DEVELOPMENT", "value": "Mobile App Development" },
//     { "key": "MOTION_GRAPHICS_DESIGN", "value": "Motion Graphics Design" },
//     { "key": "MURAL_DESIGN", "value": "Mural Design" },
//     { "key": "MUSIC", "value": "Music" },
//     { "key": "MUSIC_ARRANGEMENT", "value": "Music Arrangement" },
//     { "key": "MUSIC_PRODUCTIO_", "value": "Music Production" },
//     { "key": "PACKAGING_DESIGN", "value": "Packaging Design" },
//     { "key": "PASTRY_CHEF", "value": "Pastry Chef" },
//     { "key": "PHOTOGRAPHY", "value": "Photography" },
//     { "key": "PHOTOJOURNALISM", "value": "Photojournalism" },
//     { "key": "POETRY", "value": "Poetry" },
//     { "key": "PRINT_DESIGN", "value": "Print Design" },
//     { "key": "PUBLIC_RELATIONS", "value": "Public Relations" },
//     { "key": "PUPPETRY", "value": "Puppetry" },
//     { "key": "QUALITY_ASSURANCE_TESTING", "value": "Quality Assurance Testing" },
//     { "key": "RECIPE_DEVELOPMENT", "value": "Recipe Development" },
//     { "key": "SCREENWRITING", "value": "Screenwriting" },
//     { "key": "SCRIPTWRITING", "value": "Scriptwriting" },
//     { "key": "SEARCH_ENGINE_OPTIMIZATION", "value": "Search Engine Optimization" },
//     { "key": "SESSION_MUSICIANSHIP", "value": "Session Musicianship" },
//     { "key": "SET_DESIGN", "value": "Set Design" },
//     { "key": "SINGING", "value": "Singing" },
//     { "key": "SOCIAL_MEDIA_CONTENT_CREATION", "value": "Social Media Content Creation" },
//     { "key": "SOCIAL_MEDIA_MANAGEMENT", "value": "Social Media Management" },
//     { "key": "SOCIAL_MEDIA_STRATEGY", "value": "Social Media Strategy" },
//     { "key": "SOFTWARE_PROJECT_MANAGEMENT", "value": "Software Project Management" },
//     { "key": "SOFTWARE_ARCHITECTURE", "value": "Software Architecture" },
//     { "key": "SOFTWARE_DEVELOPMENT", "value": "Software Development" },
//     { "key": "SOFTWARE_ENGINEERING", "value": "Software Engineering" },
//     { "key": "SOUND_DESIGN", "value": "Sound Design" },
//     { "key": "SPEECHWRITING", "value": "Speechwriting" },
//     { "key": "STAND_UP_COMEDY", "value": "Stand-Up Comedy" },
//     { "key": "STREET_ART_DESIGN", "value": "Street Art Design" },
//     { "key": "STREET_PERFORMANCE", "value": "Street Performance" },
//     { "key": "STORYBOARD_DESIGN", "value": "Storyboard Design" },
//     { "key": "STORYTELLING", "value": "Storytelling" },
//     { "key": "SYSTEMS_ANALYSIS", "value": "Systems Analysis" },
//     { "key": "TECHNICAL_SUPPORT", "value": "Technical Support" },
//     { "key": "TECHNICAL_WRITING", "value": "Technical Writing" },
//     { "key": "UI_UX_DESIG_", "value": "UI/UX Design" },
//     { "key": "UI_UX_PROTOTYPING", "value": "UI/UX Prototyping" },
//     { "key": "USER_ACQUISITION", "value": "User Acquisition" },
//     { "key": "USER_EXPERIENCE_DESIGN", "value": "User Experience Design" },
//     { "key": "VIDEOGRAPHY", "value": "Videography" },
//     { "key": "VIDEO_CONTENT_PRODUCTION", "value": "Video Content Production" },
//     { "key": "VOICEOVER_ARTISTRY", "value": "Voiceover Artistry" },
//     { "key": "VIRAL_MARKETING", "value": "Viral Marketing" },
//     { "key": "VISUAL_EFFECTS", "value": "Visual Effects" },
//     { "key": "VISUAL_MERCHANDISING", "value": "Visual Merchandising" },
//     { "key": "VIRTUAL_REALITY", "value": "Virtual Reality" },
//     { "key": "VOICE_ACTING", "value": "Voice Acting" },
//     { "key": "WEB_DESIGN", "value": "Web Design" }
// ];
const CATEGORY_ARRAY = [
    "DESIGN",
    "RESEARCH",
    "DEVELOPMENT",
    "TESTING",
    "OTHER"
]
export {
    CATEGORY_ARRAY
}