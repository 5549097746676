import { click } from "@testing-library/user-event/dist/click";
import React from "react";

export const Settings = (props: { clicked?: boolean; color?: string }) => {
  let { clicked, color } = props;

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19.478 12.54C19.518 12.24 19.538 11.93 19.538 11.6C19.538 11.28 19.518 10.96 19.468 10.66L21.498 9.08C21.678 8.94 21.728 8.67 21.618 8.47L19.698 5.15C19.578 4.93 19.328 4.86 19.108 4.93L16.718 5.89C16.218 5.51 15.688 5.19 15.098 4.95L14.738 2.41C14.698 2.17 14.498 2 14.258 2H10.418C10.178 2 9.988 2.17 9.948 2.41L9.588 4.95C8.998 5.19 8.458 5.52 7.968 5.89L5.578 4.93C5.358 4.85 5.108 4.93 4.988 5.15L3.078 8.47C2.958 8.68 2.998 8.94 3.198 9.08L5.228 10.66C5.178 10.96 5.138 11.29 5.138 11.6C5.138 11.91 5.158 12.24 5.208 12.54L3.178 14.12C2.998 14.26 2.948 14.53 3.058 14.73L4.978 18.05C5.098 18.27 5.348 18.34 5.568 18.27L7.958 17.31C8.458 17.69 8.988 18.01 9.578 18.25L9.938 20.79C9.988 21.03 10.178 21.2 10.418 21.2H14.258C14.498 21.2 14.698 21.03 14.728 20.79L15.088 18.25C15.678 18.01 16.218 17.69 16.708 17.31L19.098 18.27C19.318 18.35 19.568 18.27 19.688 18.05L21.608 14.73C21.728 14.51 21.678 14.26 21.488 14.12L19.478 12.54ZM12.338 15.2C10.358 15.2 8.738 13.58 8.738 11.6C8.738 9.62 10.358 8 12.338 8C14.318 8 15.938 9.62 15.938 11.6C15.938 13.58 14.318 15.2 12.338 15.2Z"
          fill="#585858"
        />
      </svg>
    </>
  );
};
