import React from "react";

export const SucessAssign = ({ width }: any) => {
  return (
    <div>
      <svg
        width={width ? width : "138"}
        height={width ? width : "138"}
        viewBox="0 0 138 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M133 68.5C133 104.122 104.122 133 68.5 133C32.8776 133 4 104.122 4 68.5C4 32.8776 32.8776 4 68.5 4C104.122 4 133 32.8776 133 68.5ZM22.3578 68.5C22.3578 93.9836 43.0164 114.642 68.5 114.642C93.9836 114.642 114.642 93.9836 114.642 68.5C114.642 43.0164 93.9836 22.3578 68.5 22.3578C43.0164 22.3578 22.3578 43.0164 22.3578 68.5Z"
          fill="#3BB56E"
          fill-opacity="0.7"
        />
        <path
          d="M68.5013 19.1768C41.3733 19.1768 19.1777 41.3723 19.1777 68.5003C19.1777 95.6282 41.3733 117.824 68.5013 117.824C95.6292 117.824 117.825 95.6282 117.825 68.5003C117.825 41.3723 95.6292 19.1768 68.5013 19.1768ZM58.6366 93.1621L33.9748 68.5003L40.9294 61.5457L58.6366 79.2035L96.0731 41.7669L103.028 48.7709L58.6366 93.1621Z"
          fill="#319F43"
        />
      </svg>
    </div>
  );
};
