export const messages = {
  ENTER_EMAIL: "Please enter your email id",
  ERROR_ENTER_EMAIL: "Please enter valid email id",
  ENTER_NAME: "Please enter your name",
  ENTER_PASS: "Please enter your password",
  ENTER_PASS_MIN_CHAR: "Password must be at least 8 character long",
  ENTER_PASS_MAX_CHAR: "Password must be at max 20 character long",
  ENTER_LOWERCASE_CHAR: "At least one lowercase character required",
  ENTER_UPPERCASE_CHAR: "At least one uppercase character required",
  ENTER_NUMBER: "At least one number required",
  ENTER_SPECL_CHAR: "At least one special character required",
  ENTERED_PASS_MATCH: "Passwords must match",
  ENTER_CONFIRM_PASS: "Please confirm your password",
  ERROR_ENTER_ONLY_CHARACTERS: "Please enter only character",
  ERROR_FETCHING_TOKEN: "Token not found in query parameters",
  ENTER_PROJECT_TITLE: "Please enter title",
  ENTER_PROJECT_CATEGORY: "Please select category",
  ENTER_PROJECT_CURRENCY: "Please select currency",
  ENTER_PROJECT_ROYALTYTYPE: "Please select royalty type",
  ENTER_PROJECT_DURATION: "Please enter duration",
  ENTER_PROJECT_DURATIONTYPE: "Please select duration type",
  ENTER_PROJECT_TOTAL_PROJECT_FUND: "Please enter total project fund",
  ENTER_PROJECT_DESCRIPTION: "Please enter project description",
  ENTER_PROJECT_SCOPE: "Please enter project scope",
  ENTER_PROJECT_DELIVERABLES: "Please enter deliverables",
  ENTER_VALID_NUMBER: "minimum value sholud be greater than 0",
  ENTER_FUND_RELEASE_TYPE: "Please select fund release type",
  ENTER_FUND_TRANSFER_TYPE: "Please select fund transfer type",
  ENTER_ASSIGN_TO: "Please select assign fund",
  ENTER_PROJECT_DOCUMENT_REQUIREMENTS: "Please enter requirements of project",
  ENTER_PROJECT_DOCUMENT_TCDETAILS:
    "Please enter term and condition of project",
  ENTER_ABOUT: "Please provide some information about yourself",
  ENTER_PHONE_NUMBER: "Please enter your phone number",
  ENTER_UPLOAD_PHOTO: "Please upload a photo",
  ENTER_WEBSITE_LINK: "Please provide a valid link",
  ENTER_MIN_CHAR: "Too short",
  ENTER_MAX_CHAR: "Too long",
  ENTER_DESC_MAX_CHAR: "Too long",
  SUCCESS_FILE_UPLOAD: "File Uploaded",
  SUCCESS_FILE_DELETE: "File Deleted",
  ENTER_MIN_TEXT_CHAR: "Enter at least 5 character",
  ENTER_MAX_TEXT_CHAR: "Enter character upto length 50",
  ENTER_MAX_TEXTAREA_CHAR: "Enter character upto length 250",
  ADD_DOCUMENT: "At least one document is required",
  ADD_PROVIDER: "Please add provider",
  SUCCESS_LOGIN: "You're Logged In Successfully",
  SUCCESS_REGISTERED: "Registration Successful",
  SUCCESS_WALLET_CONNECTED: "Wallet Connected",
  SUCCESS_WALLET_DISCONNECTED: "Wallet Disconnected Successfully",
};