import { REACT_APP_NFT_BASE_URL, REACT_APP_SERVER_URL } from "./config";

const BASE_URL = REACT_APP_SERVER_URL;
const NFT_BASE_URL = REACT_APP_NFT_BASE_URL || "https://testnet.mirrornode.hedera.com"
export const API_ROUTES = {
  SIGNUP: `${BASE_URL}/auth/signup`,
  GOOGLE_SIGNUP: `${BASE_URL}/auth/google`,
  SEND_RESET_PASSWORD: `${BASE_URL}/auth/send-reset-password`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
  ABOUT: `${BASE_URL}/auth/about`,
  ADDRESS: `${BASE_URL}/auth/address`,
  EXPERIENCE: `${BASE_URL}/auth/experience`,
  RESEND_EMAIL: `${BASE_URL}/auth/resend-verify-email`,
  VERIFY_EMAIL: `${BASE_URL}/auth/verify-email`,
  SIGNIN: `${BASE_URL}/auth/login`,
  GET_ME: `${BASE_URL}/auth/get-me`,
  SELECT_ROLE: `${BASE_URL}/auth/role`,
  WALLET: `${BASE_URL}/auth/wallet`,
  DOCUMENT_UPLOAD: `${BASE_URL}/project/document-upload`,
  PROJECT_DETAILS: `${BASE_URL}/project`,
  INVITE: `${BASE_URL}/auth/invite`,
  GET_PROJECT_PROVIDER: `${BASE_URL}/project/providers`,
  GET_PROJECT: `${BASE_URL}/project`,
  PROJECT_DELETE: `${BASE_URL}/project`,
  GET_PROJECT_DETAIL: `${BASE_URL}/project`,
  SEARCH_PROJECT: `${BASE_URL}/project/search`,
  DOCUMENT_DELETE: `${BASE_URL}/project/document-delete`,
  TOGGLE_IP_INFO: `${BASE_URL}/project/toogle-ip-info`,
  ADD_DISPUTE: `${BASE_URL}/dispute`,
  GET_DISPUTE_MEMEBERS: `${BASE_URL}/dispute/members`,
  GET_ALL_DISPUTE: `${BASE_URL}/dispute/getAll`,
  GET_DISPUTE: `${BASE_URL}/dispute`,
  PROJECT_ACCEPT: `${BASE_URL}/project/accept-project`,
  ADD_ESCROW: `${BASE_URL}/project/add-escrow`,
  CREATE_TEAM : `${BASE_URL}/teams`,
  GET_TEAM : `${BASE_URL}/teams`,
  UPDATE_TEAM : `${BASE_URL}/teams`,
  CREATE_SUBMILESTONE: `${BASE_URL}/project/sub-milestone`,
  GET_FAQ: `${BASE_URL}/dispute/FAQ`,
  POST_FAQ: `${BASE_URL}/dispute/FAQ`,
  UPDATE_DISPUTE: `${BASE_URL}/dispute`,
  UPDATE_TRANSACTION_DETAILS:`${BASE_URL}/project/transaction`,
  GET_SHORTLIST_PROVIDER_LIST : `${BASE_URL}/project/providerList`,
  POST_SHORTLIST_PROVIDER_LIST : `${BASE_URL}/project/providerList`,
  ADD_IP: `${BASE_URL}/project/addIP`,
  MODIFY_IP: `${BASE_URL}/project/modifyIP`,
  UPDATE_MILESTONE_STATUS:`${BASE_URL}/project/milestone/status`,
  NFT_GET_ACCOUNTIFO: `${NFT_BASE_URL}/api/v1/accounts`,
  NFT_GET_TRANSACTIONS: `${NFT_BASE_URL}/api/v1/transactions`,
  PROFILE : `${BASE_URL}/auth/profile`,
  CHANGE_PASSWORD: `${BASE_URL}/auth/change-password`,
  GET_PROVIDER_DETAILS: `${BASE_URL}/auth/provider`,
  UPDATE_SHORTLIST_PROVIDER_LIST : `${BASE_URL}/project/providerList`,
  SEND_OTP: `${BASE_URL}/auth/send-otp`,
  VERIFY_OTP: `${BASE_URL}/auth/verify-otp`,
  CHANGE_PASSWORD_OTP: `${BASE_URL}/auth/change-password-otp`,
  GET_PROJECT_NOTES: `${BASE_URL}/project/notes`,
  SEND_PROJECT_NOTES: `${BASE_URL}/project/notes`,
  PROJECT_CHAT_LIST: `${BASE_URL}/project/chat-list`,
  UPLOAD_MILESTONES_DELIVERABLES: `${BASE_URL}/project/upload-deliverables`,
  NOTIFICATION: `${BASE_URL}/notification/from`,
  NOTIFICATION_TOTALCOUNT: `${BASE_URL}/notification/totalCount/from`,
  NOTIFICATION_UNREADCOUNT: `${BASE_URL}/notification/unreadCount/from`,
  UPLOAD_WEB3_STRORAGE: `${BASE_URL}/project/upload-web3-storage`,
  IP_PERMISSONS: `${BASE_URL}/project/permissions`,
  REQUEST_FUND: `${BASE_URL}/project/requestFund`,
  ADMIN_ANALYTICS : `${BASE_URL}/admin/usersOverview`,
  ADMIN_FEE : `${BASE_URL}/admin/fee`,
  ACCEPT_INVITATION : `${BASE_URL}/project/invite`,
  GET_COLLABS : `${BASE_URL}/project/collabs`,
};
