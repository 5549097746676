import { Dialog, Transition } from "@headlessui/react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useEffect } from "react";

interface PreviewDocumentModalProps {
  url: string;
  open: boolean;
  close: () => void;
  positionDiv?: any;
  PanelPosition?: any;
}

interface PermissiveConfig {
  [key: string]: any;
}

export const PreviewDocumentModal: React.FC<PreviewDocumentModalProps> = ({
  url,
  open,
  close,
  positionDiv = "flex min-h-full min-w-full items-center justify-center p-4",
  PanelPosition = "items-center",
}) => {
  console.error("::::", url);
  console.error("::encoded::", encodeURIComponent(url));

  const suppressContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const config: PermissiveConfig = {
    header: {
      disableHeader: true,
      disableFileName: true,
      retainURLParams: false,
      disableThemeScrollbar: true,
    },
    verticalScrollByDefault: true,
  };

  return (
    <>
      <Transition appear show={open}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto z-10"
          open={open}
          onClose={close}
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
          <div className="absolute inset-0 overflow-y-auto">
            <div className={positionDiv}>
              <Dialog.Panel
                className={`shadow-navbar rounded-[5px] bg-white ${PanelPosition} z-50 w-[600px] h-[600px] read-only-content aria-disabled:contents`}
                onContextMenu={suppressContextMenu}
              >
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri: encodeURI(url),
                    },
                  ]}
                  config={config}
                  prefetchMethod="GET"
                  style={{ height: 600, width: 600 }}
                  className={open ? "" : "DocViewerDownloadButtonHidden"}
                />
                <style>
                  {`
    #pdf-download {
      display: none;
    }
  `}
                </style>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
