import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Typography } from "../../Components/Atoms";

const CollabListDisplay = ({
  dataProvider,
  handleFetchMoreData,
  loader,
  AcceptInvite
}: any) => {
  return (
    <div
      id="providelistScroll"
      style={{ height: "calc(100vh - 380px)" }}
      className="max-h-full min-h-full overflow-y-auto"
    >
      <InfiniteScroll
        dataLength={dataProvider && dataProvider.length}
        next={() => {
          handleFetchMoreData();
        }}
        hasMore={true}
        loader={
          <h4 className="text-center">
            {loader
              ? "Loading..."
              : dataProvider.length === 0
                ? "No data"
                : null}
          </h4>
        }
        scrollableTarget="providelistScroll"
      >
        {dataProvider.map((provider: any, index: any) => {
          return (
            <div
              key={provider.id}
              className="w-full border-b-[1px] border-text-primary-100 px-5 py-2 flex justify-between items-center"
            >
              <div className="flex gap-5 w-1/4">
                {provider?.Purchaser?.About?.profilePictureLink ? (
                  <img
                    src={provider?.Purchaser?.About?.profilePictureLink}
                    alt="profile"
                    className="w-16 h-16  rounded-full flex justify-center items-center text-xl font-bold text-white"
                  />
                ) : (
                  <div className="w-16 h-16 capitalize bg-text-HeadLine-100 rounded-full flex justify-center items-center text-xl font-bold text-white">
                    {provider?.Purchaser?.name?.trim().charAt(0)}
                  </div>
                )}
                <div>
                  <Typography
                    type="h3"
                    label={provider?.Purchaser?.name}
                    FontSize="2xl"
                    color="primary"
                    variant={200}
                    classname="font-bold capitalize"
                  />
                  <Typography
                    type="h3"
                    label={
                      provider?.Purchaser?.Address?.[0]?.country
                    }
                    FontSize="sm"
                    color="primary"
                    variant={300}
                  />
                </div>
              </div>
              <div>
                <Typography
                  type="h3"
                  label={
                    provider?.Purchaser?.Experiences[
                      provider?.Purchaser?.Experiences?.length - 1
                    ]?.position

                  }
                  FontSize="base"
                  color="primary"
                  variant={200}
                  classname="uppercase"
                />
              </div>

              <div className="flex gap-5">
                {provider?.accpetedStatus ===
                  "PENDING" ?
                  <>
                    <div className="w-[160px]">
                      <Button
                        variant="Transparent"
                        color="secondary"
                        label="Reject"
                        className="border-[1px] tracking-wider py-2 text-lg !font-bold"
                        size="small"
                        onClick={() => AcceptInvite(provider?.id, 'REJECTED')}
                      />
                    </div>
                    <div className="w-[160px]">
                      <Button
                        variant="standard"
                        color="primary"
                        label="Accept"
                        className="border-[1px] tracking-wider py-2 text-lg !font-bold"
                        onClick={() => AcceptInvite(provider?.id, 'ACCEPTED')}
                        size="small"
                      />
                    </div>
                  </> :
                  <div className={`capitalize ${provider?.accpetedStatus === 'ACCEPTED' ? 'text-text-sucess-100' : 'text-text-danger-50'}`}>
                    {provider?.accpetedStatus?.toLowerCase()}
                  </div>}
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default CollabListDisplay;
