import { useEffect, useState } from "react";
import { Structure } from "../../Components/Molecules";
import CustomFilterDropdown from "../../Components/Molecules/Structure/CustomFiterDropdown";
import { ACCEPT_INVITATION, GET_COLLABS } from "../../sevices";
import CollabListDisplay from "./CollabListDisplay";
import { handleCustomError, showToast } from "../../Utils/helper";

const Accept = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [dataProvider, setDataProvider] = useState<any>([]);
    const [filter, setFilter] = useState({
        sortBy: "asc",
        pageNo: "",
        pageSize: "10",
    });

    function handleFetchMoreData() {
        setFilter({
            ...filter,
            pageSize: (Number(filter.pageSize) + 10).toString(),
        });
    }


    async function handleGetProjectProvider() {
        try {
            setIsLoading(true)
            const query = "?" + new URLSearchParams(filter).toString();
            let { data, status } = await GET_COLLABS(query);
            if (status === 200) {
                setDataProvider([...data]);
            }

            if (Number(filter.pageSize) === 10) {
                setHasMoreData(false);
            } else {
                setHasMoreData(data.length === dataProvider.length);
            }


        } catch (error: any) {
        } finally {
            setIsLoading(false)
        }
    }

    const AcceptInvite = async (id: string, status: string) => {
        try {
            setIsLoading(true);
            let data = await ACCEPT_INVITATION({
                id: id,
                status: status
            });
            showToast(data?.data, "success");
            handleGetProjectProvider()
        } catch (error: any) {
            handleCustomError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetProjectProvider();
    }, [
        filter.pageNo,
        filter.pageSize,
        filter.sortBy,
    ]);


    return (
        <>
            <Structure
                Heading={"Collab List"}
                optionText={
                    <>

                    </>
                }
                mainSection={
                    <div>
                        <CollabListDisplay
                            loader={isLoading}
                            dataProvider={dataProvider}
                            handleFetchMoreData={handleFetchMoreData}
                            hasMoreData={hasMoreData}
                            AcceptInvite={AcceptInvite}
                        />
                    </div>
                }
                Buttons={
                    <>
                        <div className="flex space-x-[26px] pb-[5px]">
                            <div className="relative">
                                <CustomFilterDropdown
                                    Title="Sort By"
                                    value={filter.sortBy}
                                    onChange={(selected) => {
                                        setFilter({
                                            ...filter,
                                            sortBy: selected.toString(),
                                        });
                                    }}
                                    options={[{ key: "asc", value: 'Ascending' }, { key: "desc", value: 'Descending' }]}
                                    icon={<img src="/Assets/Sort.svg" alt="" />}
                                    iconPosition="left"
                                />
                            </div>
                        </div>
                    </>
                }
            />
        </>
    );
};

export default Accept;