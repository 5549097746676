import { HashConnect, HashConnectTypes, MessageTypes } from "hashconnect";
import { HashConnectConnectionState } from "hashconnect/dist/types";
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { REACT_APP_HEDERA_NETWORK, REACT_APP_METADATA_ICON } from "../Constants/config";

//initialize hashconnect
const hashConnect = new HashConnect(true);
// const signer =  hashConnect.getSigner()

export interface SavedPairingData {
  metadata: HashConnectTypes.AppMetadata | HashConnectTypes.WalletMetadata;
  pairingData: MessageTypes.ApprovePairing;
  privKey?: string;
}

export interface PropsType {
  children: React.ReactNode;
  network: "testnet" | "mainnet" | "previewnet";
  metaData?: HashConnectTypes.AppMetadata;
  debug?: boolean;
}

//Intial App config
let APP_CONFIG: HashConnectTypes.AppMetadata = {
  name: "dApp Example",
  description: "An example hedera dApp",
  icon: "https://absolute.url/to/icon.png",
};

export interface HashconnectContextAPI {
  availableExtension: HashConnectTypes.WalletMetadata;
  state: HashConnectConnectionState;
  topic: string;
  privKey?: string;
  pairingString: string;
  pairingData: MessageTypes.ApprovePairing | null;
  acknowledgeData: MessageTypes.Acknowledge;
}

export const HashConnectAPIContext = React.createContext<
  Partial<
    HashconnectContextAPI & {
      setState: React.Dispatch<
        React.SetStateAction<Partial<HashconnectContextAPI>>
      >;
      network: "testnet" | "mainnet" | "previewnet";
      getProvider: any;
      getSigner: any;
    }
  >
>({ state: HashConnectConnectionState.Disconnected });

export const HashConnectAPIProvider = ({
  children,
  metaData,
  network,
  debug,
}: PropsType) => {
  const [cookies, setCookie, removeCookie] = useCookies(["hashconnectData"]);
  const [stateData, setState] = useState<Partial<HashconnectContextAPI>>({});

  const localData = cookies.hashconnectData as any as SavedPairingData;

  //initialise the thing
  const initializeHashConnect = useCallback(async () => {
    try {
      if (!localData) {
        if (debug) console.log("===Local data not found.=====");

        //first init and store the private for later
        let initData = await hashConnect.init(metaData ?? APP_CONFIG);
        const privateKey = initData.privKey;

        //then connect, storing the new topic for later
        const state = await hashConnect.connect();
        hashConnect.findLocalWallets();

        const topic = state.topic;

        //generate a pairing string, which you can display and generate a QR code from
        const pairingString = hashConnect.generatePairingString(
          state,
          network,
          debug ?? false
        );
        setState((exState) => ({
          ...exState,
          topic,
          privKey: privateKey,
          pairingString,
          state: HashConnectConnectionState.Disconnected,
        }));
      } else {
        if (debug) console.log("====Local data found====", localData);
        //use loaded data for initialization + connection
        await hashConnect.init(localData?.pairingData.metadata ?? APP_CONFIG, localData?.privKey);
        let state = await hashConnect.connect(
          localData?.pairingData.topic,
          localData?.pairingData.metadata ?? metaData
        );
        const topic = state.topic;
        setState((exState) => ({
          ...exState,
          topic,
          ...localData,
          state: HashConnectConnectionState.Connected,
        }));
      }
    } catch (error) {
      console.log("hashconnect",error);
    }
  }, [debug, localData, metaData, network]);

  const getProvider = (accountId?: string) => {
    let topic = stateData?.topic
    accountId ??= `${stateData?.pairingData?.accountIds[0]}`
    return hashConnect.getProvider(network, `${topic}`, accountId);
  };

  const getSigner = (provider?: any) => {
    return hashConnect.getSigner(provider);
  };

  const foundExtensionEventHandler = useCallback(
    (data: HashConnectTypes.WalletMetadata) => {
      if (debug) console.log("====foundExtensionEvent====", data);
      setState((exState) => ({ ...exState, availableExtension: data }));
    },
    [debug]
  );

  const saveDataInLocalStorage = () => {
    try {
      if (debug) {
        console.info("===============Saving to localstorage::=============");
      }
      const dataToSave = {
        metadata: stateData.availableExtension!,
        privKey: stateData.privKey!,
        pairingData: stateData.pairingData!,
        availableExtension: stateData.availableExtension!,
      };
      if (dataToSave?.pairingData?.topic && dataToSave?.pairingData?.topic !== "") {
        setCookie("hashconnectData", dataToSave, { path: "/" });
      } else {
        removeCookie("hashconnectData", { path: "/" })
      }
    } catch (error) {
      console.log('set Cookie error', error);
    }
  }

  useEffect(() => {
    saveDataInLocalStorage();
  }, [debug, stateData?.pairingString, stateData?.privKey, stateData?.pairingData, stateData?.state, stateData?.pairingData?.topic, stateData?.topic]);


  const pairingEventHandler = useCallback(
    (data: MessageTypes.ApprovePairing) => {
      console.log(HashConnectConnectionState, "status")
      if (debug) console.log("===Wallet connected=====", data);
      setState((exState) => ({ ...exState, pairingData: data, state: HashConnectConnectionState.Connected }));
    },
    [debug]
  );

  const acknowledgeEventHandler = useCallback(
    (data: MessageTypes.Acknowledge) => {
      if (debug) console.log("====::acknowledgeData::====", data);
      setState((iniData) => ({ ...iniData, acknowledgeData: data }));
    },
    [debug]
  );

  const onStatusChange = (state: HashConnectConnectionState) => {
    console.log("hashconnect state change event", state);
    setState((exState) => ({ ...exState, state }));
  };

  useEffect(() => {
    initializeHashConnect();
  }, []);

  useEffect(() => {
    hashConnect.foundExtensionEvent.on(foundExtensionEventHandler);
    hashConnect.pairingEvent.on(pairingEventHandler);
    hashConnect.acknowledgeMessageEvent.on(acknowledgeEventHandler);
    hashConnect.connectionStatusChange.on(onStatusChange);
    return () => {
      hashConnect.foundExtensionEvent.off(foundExtensionEventHandler);
      hashConnect.pairingEvent.off(pairingEventHandler);
      hashConnect.acknowledgeMessageEvent.off(acknowledgeEventHandler);
    };
  }, []);

  return (
    <HashConnectAPIContext.Provider
      value={{ ...stateData, setState, network, getProvider, getSigner }}
    >
      {children}
    </HashConnectAPIContext.Provider>
  );
};

const defaultProps: Partial<PropsType> = {
  metaData: {
    name: "M.O.",
    description: "The M.O.",
    icon: REACT_APP_METADATA_ICON as any,
  },
  network: REACT_APP_HEDERA_NETWORK as any,
  debug: false,
};

HashConnectAPIProvider.defaultProps = defaultProps;

// export const HashConnectProvider = React.memo(HashConnectProviderWarped);

export const useHashConnect = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["hashconnectData"]);
  const value = React.useContext(HashConnectAPIContext);
  const { topic, pairingString, setState } = value;

  const connectToExtension = async () => {
    //this will automatically pop up a pairing request in the HashPack extension
    hashConnect.connectToLocalWallet(pairingString!);
  };

  const sendTransaction = async (
    trans: Uint8Array,
    acctToSign: string,
    return_trans: boolean = false,
    hideNfts: boolean = false
  ) => {
    const transaction: MessageTypes.Transaction = {
      topic: topic!,
      byteArray: trans,

      metadata: {
        accountToSign: acctToSign,
        returnTransaction: return_trans,
      },
    };

    return await hashConnect.sendTransaction(topic!, transaction);
  };

  const disconnect = () => {
    removeCookie("hashconnectData", { path: "/" });
    window.location.reload()
    setState!((exData) => ({
      ...exData, pairingData: null, state: HashConnectConnectionState.Disconnected,
    }));
  };

  return { ...value, connectToExtension, sendTransaction, disconnect };
};

// export default HashConnectProvider;