const ENV = window.location.href.includes('https://app.the-mo.net')
    ? true
    : false;
export const REACT_APP_SERVER_URL = ENV
    ? process.env.REACT_APP_SERVER_URL_PROD
    : process.env.REACT_APP_SERVER_URL;

export const REACT_APP_NFT_BASE_URL = ENV
    ? process.env.REACT_APP_NFT_BASE_URL_PROD
    : process.env.REACT_APP_NFT_BASE_URL;

export const REACT_APP_USDC_TOKEN_ID = ENV
    ? process.env.REACT_APP_USDC_TOKEN_ID_PROD
    : process.env.REACT_APP_USDC_TOKEN_ID;

export const REACT_APP_HEDERA_NETWORK = ENV
    ? process.env.REACT_APP_HEDERA_NETWORK_PROD
    : process.env.REACT_APP_HEDERA_NETWORK;

export const REACT_APP_METADATA_ICON = ENV
    ? process.env.REACT_APP_METADATA_ICON_PROD
    : process.env.REACT_APP_METADATA_ICON;

export const REACT_APP_NODE_ENV = ENV
    ? process.env.REACT_APP_NODE_ENV_PROD
    : process.env.REACT_APP_NODE_ENV;

export const REACT_APP_MQ_HOST = ENV
    ? process.env.REACT_APP_MQ_HOST_PROD
    : process.env.REACT_APP_MQ_HOST;

export const REACT_APP_MQ_USERNAME = ENV
    ? process.env.REACT_APP_MQ_USERNAME_PROD
    : process.env.REACT_APP_MQ_USERNAME;

export const REACT_APP_MQ_PASSWORD = ENV
    ? process.env.REACT_APP_MQ_PASSWORD_PROD
    : process.env.REACT_APP_MQ_PASSWORD;

